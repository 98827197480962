import React, { Component } from 'react';
import queryString from 'query-string';
import Navbar from './Navbar';
import PostList from './PostList';
import Post from './Post';
import Gallery from './Gallery';
import Sidebar from './Sidebar';
import {ViewType,site_url} from './utilTypes';
import '../css/Main.css';
import title_img from '../images/title.png';

class Main extends Component<any, {viewType : ViewType, category? : string, post? : any}> {
  
  constructor(props : any) {
    super(props);
    this.state = {viewType : ViewType.Home};
    this.nav_handler = this.nav_handler.bind(this);
    this.postlist_handler = this.postlist_handler.bind(this);
  }

  nav_handler(vt : ViewType, category_name? : string) {
    this.setState({viewType : vt, category : category_name});
  }

  postlist_handler(show_post : any) {
    this.setState({viewType: ViewType.Post, post : show_post});
  }

  switchBG() {
    let bgdiv: HTMLElement| null = document.getElementById("main4");
    if (bgdiv && bgdiv.style.opacity == "1") bgdiv.style.opacity = "0"
    else if (bgdiv) bgdiv.style.opacity = "1";
  }

  componentDidMount() {
    const queryValues = queryString.parse(this.props.location.search);
    if (queryValues.vt == "gallery") this.setState({viewType : ViewType.Gallery});
    
    setInterval(this.switchBG, 20000);
  }

  render() {

    let currentViewType : any;

    switch (this.state.viewType) {
      case ViewType.Home: {
        currentViewType = <PostList handler={this.postlist_handler} />;
        break;
      }
      case ViewType.PostList: {
        currentViewType = <PostList category={this.state.category} handler={this.postlist_handler}/>;
        break;
      }
      case ViewType.Post : {
        currentViewType = <Post post={this.state.post} />
        break;
      }
      case ViewType.Gallery : {
        currentViewType = <Gallery />
        break;
      }
      default: {
        currentViewType = <PostList handler={this.postlist_handler} />
        break;
      }
    }

    //currentViewType = <div className="temp-home">Coming Soon!</div>

    return (
      <div className="Main1" id="mainbg">
        <div className="Main3">
        <div className="header"><img src={title_img} alt="title"/></div>
        <Navbar handler={this.nav_handler}/>
        <div className="content">
        {currentViewType}
        <div className="clearing"></div>
        </div>
        <Sidebar pageHandler={this.postlist_handler} postHandler={this.postlist_handler} />
        <div className="clearing"></div>
        </div><div className="Main4" id="main4"></div>
      </div>
    );
  }
}

export default Main;
