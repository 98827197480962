import React, {Component} from 'react';
import {site_url} from './utilTypes';
import '../css/Sidebar.css';

export default class Sidebar extends Component<any, {pages: Array<any>}> {

    constructor(props : any) {
        super(props);
        this.state = {pages : []};
    }

    getPages() {
        let pages_url : string = site_url + "/wordpress/?rest_route=/wp/v2/pages";

        fetch(pages_url)
        .then((result) => result.json())
        .then((post_data) => {this.setState({pages : post_data})});          

    }

    componentDidMount() {
        this.getPages();
    }

    render() {
        let myPages : Array<any> = [];

        for (let page of this.state.pages) {
            myPages.push(<li onClick={() => {this.props.pageHandler(page)}}>{page.title.rendered}</li>);
        }

        myPages.reverse();

        return <div className="sidebar">
        <ul>
        {myPages}
        </ul>
        </div>
    }
}