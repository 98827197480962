import React, { Component } from 'react';
import { Title, Author, Content, Excerpt} from './PostElements';
import wpPost from './typings/wp_types';
import '../css/Post.css';

class Post extends Component<{post? : any}, {}> {
    constructor(props? : any) {
        super(props);
    }

    render() {

        return (
            <div className="post">
                <Title title={this.props.post?this.props.post.title:undefined} />
                <Author author={this.props.post?this.props.post.author:undefined} />
                <Content content={this.props.post?this.props.post.content:undefined} />
            </div>
        )
    }
}

export class PostExcerpt extends Component<{post : any, handler : (post : any) => void}, {}> {
    constructor(props? : any) {
        super(props);
    }

    render() {

        return (
            <div className="postExcerpt" onClick={() => {this.props.handler(this.props.post)}}>
                <Title title={this.props.post?this.props.post.title:undefined} />
                <Author author={this.props.post?this.props.post.author:undefined} />
                <Excerpt excerpt={this.props.post?this.props.post.excerpt:undefined} />
            </div>
        )
    }
}

export default Post;