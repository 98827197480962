import React, { Component } from 'react';
import { wpTitle, wpContent, wpExcerpt } from './typings/wp_types';
import {site_url} from './utilTypes';

export class Title extends Component<{title? :wpTitle}, {}> {
    render() {
        return <h1>{this.props.title?this.props.title.rendered:"Title"}</h1>
    }
}

export class Author extends Component<{author? : number}, {author_name : string}> {
    
    constructor(props : any) {
        super(props);
        this.state = {author_name : ""};
    }

    getAuthor(authorID : number) {
        
        let name : string = "";

        let base_url = site_url + "/wordpress/?rest_route=/wp/v2/";
        let url = base_url + "users/" + authorID.toString();
        
        fetch(url)
            .then((result) => result.json())
            .then((post_data) => {this.setState({author_name : post_data.name.toString()})});
    }

    componentDidMount() {
        this.getAuthor(this.props.author?this.props.author:1);
    }
    
    render() {
        
        return <div className="author">Written by {this.state.author_name}</div>
    }
}

export class Excerpt extends Component<{excerpt? : wpExcerpt}, {}> {
    render() {
        return <div dangerouslySetInnerHTML={{__html : this.props.excerpt?this.props.excerpt.rendered:"Fetching content..."}} />
    }
}

export class Content extends Component<{content? : wpContent}, {}> {
    render() {
        return <div dangerouslySetInnerHTML={{__html : this.props.content?this.props.content.rendered:"Fetching content..."}} />
    }
}