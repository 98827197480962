import React, {Component} from 'react';
import {site_url} from './utilTypes';
import '../css/Gallery.css';

export default class Gallery extends Component<any, {pics : Array<any>, current_pic: any, showing : boolean}> {
    constructor(props: any) {
        super(props);

        this.state = {pics: [], current_pic : null, showing : false};
    }

    display_image(pic : any) {
        this.setState({current_pic : pic, showing : true});
    }

    close_pic() {
        this.setState({showing : false});
    }

  fetchPics() {
    let pics_url : string = site_url + '/wordpress/?rest_route=/wp/v2/media';

    fetch(pics_url)
      .then((result) => result.json())
      .then((pics_data) => {this.setState({pics : pics_data})});          
  }

  componentDidMount() {
      this.fetchPics();
  }

  render() {
    let myPics : Array<any> = [];
    let myPic : any;

    let i : number = 0;

    for (let pic of this.state.pics) {

      myPics.push(<div className="img_container" key={i.toString()+"div4pic"}><img className="pic_thumbnail" onClick={() => {this.display_image(pic)}} key={i.toString()+"pic"} src={pic.media_details.sizes.medium.source_url}/> </div>);
      i++;
      if (i%4 == 0) myPics.push(<div className="clearing" key={i.toString()+"div"}></div>);
    }

    if (this.state.showing) {
        myPic = <div className="hoverPic" onClick={() => {this.close_pic()}}>
                  <span className="close" onClick={() => {this.close_pic()}}>Close</span>
                  <figure>
                     <img className="full_picture" src={this.state.current_pic.source_url} />
                     <figcaption dangerouslySetInnerHTML={{__html:this.state.current_pic.caption.rendered}}></figcaption>
                  </figure>
                 
                 
                 
                </div>
    } else myPic = <div />

    return (<div className="gallery">
        {myPics}
        {myPic}
        </div>
    );
  }    
}