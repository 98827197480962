import React, { Component } from 'react';
import Button from './Button';
import {ViewType} from './utilTypes';
import '../css/Navbar.css';

export default class Navbar extends Component<any, any> {
    render() {
        return <div className="navbar">
            <Button label="Home" viewType={ViewType.Home} handler={this.props.handler}/>
            <Button label="Recipes" category_slug="recipes" viewType={ViewType.PostList} handler={this.props.handler} />
            <Button label="Crafts" category_slug="crafts" viewType={ViewType.PostList} handler={this.props.handler} />
            <Button label="Farm" category_slug="farm" viewType={ViewType.PostList} handler={this.props.handler} />
            <Button label="Gallery" viewType={ViewType.Gallery} handler={this.props.handler}/> 
            <div className="clearing"></div>
         </div>
    };
};