import React, { Component } from 'react';
import Post, {PostExcerpt} from './Post';
import {site_url} from './utilTypes';
import '../css/Main.css';

class PostList extends Component<{category? : string, handler : (post : any) => void}, {posts: Array<any>, isLoading : boolean}> {
  constructor(props : any) {
    super(props);

    this.state = {posts : [], isLoading : true};
  }

  fetchPosts(id? : number) {
    let posts_url : string = site_url + '/wordpress/?rest_route=/wp/v2/posts';
    if (id != undefined) {
      posts_url = posts_url + '&categories=' + id;              
    }

    fetch(posts_url)
      .then((result) => result.json())
      .then((post_data) => {this.setState({posts : post_data})}).then(() => {this.setState({isLoading:false})});          
  }

  getPosts(current_category? : string) {
    let category_url : string = site_url + '/wordpress/?rest_route=/wp/v2/categories';

    if (current_category != undefined) {
      category_url = category_url + '&slug=' + current_category;
    }

    fetch(category_url)
      .then((result) => result.json())
      .then((result) => {if (current_category != undefined) this.fetchPosts(result[0].id);
                         else this.fetchPosts();
                        })
  };

  componentDidMount() {
      this.getPosts(this.props.category);
  }

  componentWillReceiveProps(nextProps : any) {
    if (this.props.category != nextProps.category) {
      this.getPosts(nextProps.category);
  }
  }

  render() {
    let myPosts : Array<any> = [];
    let loadingPage : any = <p>Retrieving posts...</p>;

    let i : number = 0;

    for (let post of this.state.posts) {

      myPosts.push(<PostExcerpt post={post} key={i.toString()+"post"} handler={this.props.handler}/>);
      i++;
      if (i%3 == 0) myPosts.push(<div className="clearing" key={i.toString()+"div"}></div>);
    }

    return (<div>
        {this.state.isLoading ? loadingPage: myPosts}
        </div>
    );
  }
}

export default PostList;
